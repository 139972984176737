import { JCRichText } from "@/components";
import { Favorite } from "@/components/Favorite";
import { usePromotionTag } from "@/lib/preFetch/prefetchPromoTag";
import { Link, NextImage, ProductCarousel, ProductsContainer, PromotionalImage, toFeaturedValidProduct, transMoney, useFeaturedProducts, useI18n, usePdpPrice, } from "@jog/react-components";
import { getGtmIdFromCT, typedBoolean } from "@jog/shared";
import { asLink, asText, isFilled } from "@prismicio/helpers";
import classNames from "classnames";
import { useMemo } from "react";
const ProductItem = ({ product, className, backgroundColor, }) => {
    var _a;
    const promotionTag = usePromotionTag(product.promotionalBanner);
    const { currentPrice, originPrice, off } = usePdpPrice(product);
    const { $t } = useI18n();
    return (<div className={className}>
            <div className="h-full bg-white" style={{ backgroundColor }}>
                <Link to={product.path} target={(_a = product.target) !== null && _a !== void 0 ? _a : "_self"} className="inline-block h-full w-full text-gray-base hover:text-gray-base hover:no-underline">
                    <div className="relative flex h-full flex-col">
                        <div className="imageBlock group relative">
                            <NextImage alt={product.cover.alt} height={500} width={330} className="img-fluid h-full w-full object-cover" src={product.cover.url}/>
                            <PromotionalImage className="absolute -top-1.25 -right-1.25 w-17.5" promotionTag={promotionTag}/>
                            <Favorite productId={product.id} name={product.name} price={+(product.firstPrice || product.price)} gtmId={getGtmIdFromCT(product.attributes)}/>
                        </div>
                        <div className="flex min-h-0 flex-1 flex-col justify-between">
                            <span className="mt-2.75 mb-1 flex-1 py-0 px-4 text-base uppercase">{product.name}</span>
                            <span className="mb-1.5 flex flex-col gap-2 py-0 px-4">
                                {off > 0 ? (<>
                                        <span className="mt-2 font-primary text-[13px] leading-none text-gray-base">
                                            <span>{`${$t(`old price`)}: `}</span>
                                            <span className="line-through">{transMoney(originPrice)}</span>
                                        </span>
                                        <span className="font-primary text-[18px] font-bold leading-none text-red-main">
                                            {transMoney(currentPrice)}
                                        </span>
                                    </>) : (<span className="font-primary text-[18px] font-bold leading-none text-gray-base">
                                        {transMoney(currentPrice)}
                                    </span>)}
                            </span>
                        </div>
                    </div>
                </Link>
            </div>
        </div>);
};
const gridMap = {
    4: "lg:grid-cols-4",
    5: "lg:grid-cols-5",
    6: "lg:grid-cols-6",
};
export const useValidProducts = (items) => {
    const { products } = useFeaturedProducts();
    return useMemo(() => items
        .filter((i) => isFilled.link(i.product_link))
        .map(({ product_link, cover }) => {
        const product = products.find((product) => { var _a; return (_a = asLink(product_link)) === null || _a === void 0 ? void 0 : _a.includes(product.path); });
        if (!product)
            return;
        return toFeaturedValidProduct({ product_link, cover }, product);
    })
        .filter(typedBoolean), [items, products]);
};
const Carousel = ({ items, primary, }) => (<ProductCarousel CarouselContainer={({ children, className }) => (<div className={classNames(className, "-ml-3 pt-1.25")}>{children}</div>)} items={items.slice(0, 20)} displayNumber={+primary.display_number} CarouselItem={({ item, className }) => (<ProductItem product={item} className={classNames(className, "pl-3")} backgroundColor={primary.background_color}/>)} Button={({ className, ...props }) => (<button {...props} className={classNames(className, "hover:text-green-dark")}/>)}/>);
export const FeaturedProducts = ({ slice: { primary, items }, className }) => {
    const validProduct = useValidProducts(items);
    if (!validProduct.length)
        return <></>;
    return (<ProductsContainer className={className} backgroundColor={primary.background_color} descriptionColor={primary.description_color} titleColor={primary.title_color} alignment={primary.alignment} title={asText(primary.title)} description={primary.description}>
            {primary.carousel ? (<Carousel items={validProduct.slice(0, 20)} primary={primary}/>) : (<div className={classNames("grid grid-cols-2 gap-3 py-3", gridMap[primary.display_number] || gridMap["6"])}>
                    {validProduct.slice(0, +primary.display_number || 6).map((product, index) => (<ProductItem backgroundColor={primary.background_color} product={product} key={index}/>))}
                </div>)}
        </ProductsContainer>);
};
export const CarouselProducts = ({ slice: { primary, items }, className }) => {
    const max = +primary.maximum_amount || 20;
    const maximumAmount = max < +primary.display_number ? 20 : max;
    const validProduct = useValidProducts(items);
    if (!validProduct.length)
        return <></>;
    return (<ProductsContainer className={className} backgroundColor={primary.background_color} descriptionColor={primary.description_color} titleColor={primary.title_color} alignment={primary.alignment} title={asText(primary.title)} description={isFilled.richText(primary.descriptions) ? <JCRichText field={primary.descriptions}/> : null}>
            <Carousel items={validProduct.slice(0, maximumAmount)} primary={primary}/>
        </ProductsContainer>);
};
